<article class="App" [class.Theme--dark]="theme() === UiTheme.dark">
	<!-- Environment -->
	<!-- <gld-environment-decorator /> -->

	@defer (on immediate) {
		<mat-sidenav-container (backdropClick)="onCloseSidenav()">
			<!-- Sidenav -->
			<mat-sidenav
				disableClose
				mode="push"
				position="end"
				role="navigation"
				class="App-sidenav"
				[autoFocus]="false"
				[opened]="showSidenav()"
			>
				<gld-sidenav />
			</mat-sidenav>

			<!-- Main -->
			<mat-sidenav-content>
				<main #mainContent class="App-main">
					<router-outlet (activate)="onActivate()" />
				</main>
			</mat-sidenav-content>
		</mat-sidenav-container>
	} @placeholder (minimum 400ms) {
		<gld-loader loading background minHeight="100dvh" [theme]="theme()" />
	}
</article>
